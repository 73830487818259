import { CreateOutlined, DeleteOutlineOutlined } from "@mui/icons-material";
import { Box, Chip, styled, useMediaQuery } from "@mui/material";
import {
	GridActionsCellItem,
	GridColDef,
	GridRenderCellParams,
	GridRowParams,
} from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EmptyListAnimation } from "../../animations/EmptyListAnimation";
// import useDataset from "../../hooks/useDataset";
import { TagResponse } from "../../openapi";
// import { storeDatasetDetails } from "../../redux/features/datasets/datasetsSlice";
import { saveModalName } from "../../redux/features/modal/modalSlice";
import { RootState } from "../../redux/store";
import { theme } from "../../theme";
import {
	CONTENT_MODAL,
	PREVIEW_MODAL,
	// DELETE_CONTENT_MODAL
} from "../../utils/constants";
import DeleteContentModal from "../modals/DeleteContentModal";
import { TableTemplate } from "../table/TableTemplate";
import { TeamDataGrid } from "./PreviewDataGrid";
import usePreview from "../../hooks/usePreview";
import PreviewCards from "./PreviewCards";
import CustomPagination from "../pagination/CustomPagination";
import ResultItems from "./ResultItems";
import NewSearchBar from "../common/NewSearchBar";
import PrimaryButton from "../buttons/PrimaryButton";
import { clearPreviewDetils } from "../../redux/features/previews/previewSlice";
import AddIcon from "@mui/icons-material/Add";

const SearchFormWrapper = styled(Box)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	gap: "8px",
	// height: "40px",
	// marginLeft: "124px",
	marginTop: "24px",
	marginBottom: "24px",
	// [theme.breakpoints.down("lg")]: {
	// 	flexDirection: "row",
	// 	// alignItems: "flex-start",
	// 	height: "40px",
	// 	margin: "16px 32px",
	// },
	// [theme.breakpoints.down("sm")]: {
	// 	flexDirection: "column",
	// 	alignItems: "flex-start",
	// 	height: "90px",
	// 	margin: "16px 32px",
	// },
}));

const PreviewsList = () => {
	// const { getDatasetList, getDatasetById } = useDataset();

	const {
		// createContent,
		getPreviewList,
		// updatePreview,
		// deletePreview,
		getPreviewsByUrls,
	} = usePreview();
	const dispatch = useDispatch();
	const isXs = useMediaQuery(theme.breakpoints.only("xs"));
	const isSm = useMediaQuery(theme.breakpoints.up("sm"));

	const [searchContent, setSearchContent] = useState("");

	const handleChange = (value: string) => {
		console.log("handleChange for: ", value);
		const inputText = value;
		setSearchContent(inputText ?? "");
	};
	const handleSubmit = () => {
		console.log(searchContent);
		getPreviewList(page, searchContent);
	};
	const onCancelResearch = (searchContent: string) => {
		console.log(searchContent);
		setSearchContent("");
		getPreviewList(page);
	};

	//const [searchValue, setSearchValue] = useState<string | null>(null);
	const [page, setPage] = useState(1);
	// const { datasets, pagination, isLoading } = useSelector(
	// 	(state: RootState) => state.dataset
	// );
	const {
		previewList,
		// preview,
		pagination,
		isLoading,
	} = useSelector((state: RootState) => state.preview);
	const handlePageChange = (event: any, newPage: any) => {
		setPage(newPage);
	};

	const handleEditClick = (params: GridRowParams) => {
		getPreviewsByUrls(params.row.url).then((r: any) => {
			dispatch(saveModalName(PREVIEW_MODAL));
		});
	};
	const handleDelete = (params: GridRowParams) => {
		console.log("params.row", params.row);
		// dispatch(storeDatasetDetails(params.row));
		// dispatch(saveModalName(DELETE_CONTENT_MODAL));
	};

	const handleUploadModal = () => {
		dispatch(clearPreviewDetils());
		dispatch(saveModalName(PREVIEW_MODAL));
	};
	const columns: GridColDef[] = [
		{
			field: "url",
			headerName: "Url",
			sortable: false,
			// flex: isSm ? 0.2 : undefined,
			// width: isXs ? 70 : undefined,
		},
		{
			field: "title",
			headerName: "Title",
			sortable: false,
			flex: isSm ? 0.7 : undefined,
			width: isXs ? 180 : undefined,
		},
		{
			field: "description",
			headerName: "Description",
			sortable: false,
			flex: isSm ? 0.7 : undefined,
			width: isXs ? 180 : undefined,
		},
		{
			field: "favicon",
			headerName: "Favicon",
			sortable: false,
			flex: isSm ? 1 : undefined,
			width: isXs ? 220 : undefined,
			// renderCell: (params: GridRenderCellParams) => {
			// 	let keywordLeft = null;
			// 	if (params.row.keywords.length > 2) {
			// 		keywordLeft = params.row.keywords.length - 2;
			// 	}
			// 	return (
			// 		<Box maxWidth={"150px"}>
			// 			{params.row.keywords.length > 2 ? (
			// 				<>
			// 					<Chip
			// 						variant="filled"
			// 						label={params.row.keywords[0].title}
			// 						size="small"
			// 						sx={{
			// 							borderRadius: "4px",
			// 							fontSize: "14px",
			// 							marginRight: "10px",
			// 						}}
			// 					/>
			// 					<Chip
			// 						variant="filled"
			// 						label={params.row.keywords[1].title}
			// 						size="small"
			// 						sx={{
			// 							borderRadius: "4px",
			// 							fontSize: "14px",
			// 							marginRight: "10px",
			// 						}}
			// 					/>
			// 					+ {keywordLeft}
			// 				</>
			// 			) : params.row.keywords.length <= 2 ||
			// 			  params.row.keywords.length !== 0 ? (
			// 				params.row.keywords.map(
			// 					(data: TagResponse, index: number) => {
			// 						return (
			// 							<Chip
			// 								key={index}
			// 								variant="filled"
			// 								label={data.title}
			// 								size="small"
			// 								sx={{
			// 									borderRadius: "4px",
			// 									fontSize: "14px",
			// 									marginRight: "10px",
			// 								}}
			// 							/>
			// 						);
			// 					}
			// 				)
			// 			) : null}
			// 		</Box>
			// 	);
			// },
		},
		{
			field: "site_name",
			headerName: "Site Name",
			sortable: false,
			flex: isSm ? 0.7 : undefined,
			width: isXs ? 180 : undefined,
		},
		// {
		// 	field: "site_name",
		// 	headerName: "Site Name",
		// 	sortable: false,
		// 	flex: isSm ? 0.7 : undefined,
		// 	width: isXs ? 180 : undefined,
		// },
		// {
		// 	field: "tags",
		// 	headerName: "Tags",
		// 	sortable: false,
		// 	flex: isSm ? 1 : undefined,
		// 	width: isXs ? 220 : undefined,
		// 	renderCell: (params: GridRenderCellParams) => {
		// 		let keywordLeft = null;
		// 		if (params.row.tags.length > 2) {
		// 			keywordLeft = params.row.tags.length - 2;
		// 		}
		// 		return (
		// 			<Box maxWidth={"150px"}>
		// 				{params.row.tags.length > 2 ? (
		// 					<>
		// 						<Chip
		// 							variant="filled"
		// 							label={params.row.tags[0].title}
		// 							size="small"
		// 							sx={{
		// 								borderRadius: "4px",
		// 								fontSize: "14px",
		// 								marginRight: "10px",
		// 							}}
		// 						/>
		// 						<Chip
		// 							variant="filled"
		// 							label={params.row.tags[1].title}
		// 							size="small"
		// 							sx={{
		// 								borderRadius: "4px",
		// 								fontSize: "14px",
		// 								marginRight: "10px",
		// 							}}
		// 						/>
		// 						+ {keywordLeft}
		// 					</>
		// 				) : params.row.tags.length <= 2 ||
		// 				  params.row.tags.length !== 0 ? (
		// 					params.row.tags.map(
		// 						(data: TagResponse, index: number) => {
		// 							return (
		// 								<Chip
		// 									key={index}
		// 									variant="filled"
		// 									label={data.title}
		// 									size="small"
		// 									sx={{
		// 										borderRadius: "4px",
		// 										fontSize: "14px",
		// 										marginRight: "10px",
		// 									}}
		// 								/>
		// 							);
		// 						}
		// 					)
		// 				) : null}
		// 			</Box>
		// 		);
		// 	},
		// },
		{
			field: "actions",
			type: "actions",
			headerName: "Actions",
			width: 80,
			getActions: (params) => [
				<GridActionsCellItem
					icon={<CreateOutlined fontSize="small" />}
					label="Edit"
					onClick={() => handleEditClick(params)}
				/>,
				<GridActionsCellItem
					icon={<DeleteOutlineOutlined fontSize="small" />}
					label="Delete"
					onClick={() => handleDelete(params)}
				/>,
			],
		},
	];
	// let searchedListers: any[] = [];
	// const handleSearch = async (value: string) => {
	//   setSearchValue(value);
	// };
	useEffect(() => {
		console.log("page", page);
		getPreviewList(page);
	}, [page]);
	return (
		<Box>
			{/* {pagination && previewList && (
				<CustomPagination
					count={pagination?.totalPage}
					page={page}
					rowCount={pagination?.total || previewList?.length || 0}
					onPageChange={handlePageChange}
				/>
			)} */}
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					// marginBottom: "24px",
				}}
			>
				<PrimaryButton
					text="Add Preview"
					startIcon={<AddIcon />}
					onClick={handleUploadModal}
				/>
				<SearchFormWrapper>
					<NewSearchBar
						searchContent={searchContent}
						setSearchContent={setSearchContent}
						onSearch={handleSubmit}
						onCancelResearch={onCancelResearch}
						onChange={handleChange}
					/>
				</SearchFormWrapper>
			</Box>
			<ResultItems items={previewList} />
			{/* <PreviewCards previews={previewList} /> */}
			{/* <TableTemplate
				// search={
				//   <SearchInput
				//     searchCallback={handleSearch}
				//     searchLabel={`Search Team Members...`}
				//   />
				// }
				list={previewList}
				dataGrid={
					<TeamDataGrid
						paginationData={pagination}
						list={previewList}
						columns={columns}
						page={page}
						handlePageChange={handlePageChange}
					/>
				}
				buttonGroups={null}
				emptyView={<EmptyListAnimation title="No content found" />}
				loading={isLoading}
			/>
			<DeleteContentModal /> */}
			{pagination && previewList && (
				<CustomPagination
					count={pagination?.totalPage}
					page={page}
					rowCount={pagination?.total || previewList?.length || 0}
					onPageChange={handlePageChange}
				/>
			)}
		</Box>
	);
};
export default PreviewsList;
